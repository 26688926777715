/* SalesForm.css */

.sales-form-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  margin: 0;
}

.form-column {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

.line-items {
  margin-top: 20px;
}

.line-items h3 {
  margin-bottom: 10px;
}

.line-item {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.line-item input {
  flex: 1;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.save-button.disabled {
  background-color: grey;
  cursor: not-allowed;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #ddd;
}

button:focus {
  outline: none;
}

button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 12px;
}

.confirmation-message {
  color: green;
  font-weight: bold;
  margin-bottom: 10px;
}

/* General Styles */
.data-list {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .data-list .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #007bff; /* Hospital Blue */
    color: #fff;
    border-radius: 10px;
  }
  
  .data-list .header h2 {
    font-size: 1.5rem;
    margin: 0;
    color: white;
  }
  
  .data-list .header .actions {
    display: flex;
    align-items: center;
  }
  
  .data-list .header .add-button {
    background-color: #28a745; /* Green for Add */
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .data-list .header .add-button:hover {
    background-color: #218838; /* Darker Green */
  }
  
  .data-list .header .bulk-actions {
    display: flex;
    align-items: center;
    color: white;
  }
  
  .data-list .header .bulk-actions select {
    margin-left: 10px;
    padding: 5px;
    border-radius: 5px;
    border: none;
    font-size: 1rem;
  }
  
  .data-list .header .bulk-actions select:focus {
    outline: none;
  }
  
  /* DataTable Styles */
  .data-list .data-table-container {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
  }
  
  .data-list .rdt_TableHead {
    background-color: #e9ecef; /* Light grey for headers */
    color: #343a40; /* Dark text for contrast */
    font-weight: bold;
  }
  
  .data-list .rdt_TableRow {
    transition: background-color 0.2s;
  }
  
  .data-list .rdt_TableRow:hover {
    background-color: #f1f1f1; /* Slight hover effect */
  }
  
  .data-list .rdt_TableCell {
    padding: 10px;
  }
  
  .data-list .rdt_TableCol {
    padding: 10px;
  }
  
  .data-list .rdt_TableBody {
    background-color: white;
  }
  
  .data-list .rdt_Pagination {
    margin-top: 20px;
  }
  
  /* Buttons */
  .data-list button {
    background-color: transparent;
    border: none;
    color: #007bff;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .data-list button:hover {
    color: #0056b3; /* Darker blue */
  }
  
  .data-list .edit-button {
    margin-right: 10px;
  }
  
  .data-list .delete-button {
    color: #dc3545; /* Red for delete */
  }
  
  .data-list .delete-button:hover {
    color: #c82333; /* Darker red */
  }
  
  /* Modal Styles */
  .data-list .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .data-list .modal h2 {
    margin-top: 0;
  }
  
  .data-list .modal .form-group-horizontal {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  
  .data-list .modal .form-group-horizontal label {
    width: 30%;
    font-weight: bold;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  
  .data-list .modal .form-group-horizontal input,
  .data-list .modal .form-group-horizontal .react-select__control {
    flex:1;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .data-list .modal .form-group-horizontal input:focus,
  .data-list .modal .form-group-horizontal select:focus {
    outline: none;
    border-color: #007bff; /* Blue focus */
  }
  
  .data-list .modal .form-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .data-list .modal .form-actions button {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  
  .data-list .modal .form-actions .save-button {
    background-color: #007bff; /* Blue for save */
    color: white;
  }
  
  .data-list .modal .form-actions .save-button:hover {
    background-color: #0056b3; /* Darker blue */
  }
  
  .data-list .modal .form-actions .cancel-button {
    background-color: #6c757d; /* Grey for cancel */
    color: white;
  }
  
  .data-list .modal .form-actions .cancel-button:hover {
    background-color: #5a6268; /* Darker grey */
  }
  .data-list .filter-item {
    padding: 10px 10px;
  }
/* Tabs container */
.tabs {
  display: flex;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

/* Individual tab buttons */
.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: lightblue;
  font-size: 16px;
  font-weight: 500;
  color: #555;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
}

/* Active tab */
.tab-button.active {
  color: #007bff; /* Highlighted color for active tab */
  border-bottom: 2px solid #007bff; /* Underline active tab */
  font-weight: 600; /* Slightly bolder text */
}

/* Hover effect for tabs */
.tab-button:hover {
  color: #0056b3;
}

/* Disabled tabs (if needed for future use) */
.tab-button:disabled {
  color: #999;
  cursor: not-allowed;
}

/* Optional styling for responsiveness */
@media (max-width: 600px) {
  .tab-button {
    font-size: 14px;
    padding: 8px 15px;
  }
}
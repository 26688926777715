.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ccc;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo {
    width: 80px; /* Adjust the width of the logo as needed */
    height: auto;
    margin-right: 10px;
  }
  
  .logo-container h1 {
    margin: 0;
  }
  
  .user-menu {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .home-icon {
    margin-right: 20px;
    text-decoration: none;
    color: black;
  }
  
  .user-info {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .user-info span {
    margin: 0 10px;
  }
  
  .account-icon {
    margin-left: 10px;
  }
  
  .dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: none;
    flex-direction: column;
    padding: 10px;
  }
  
  .dropdown-menu.show {
    display: flex;
  }
  
  .dropdown-menu a,
  .dropdown-menu button {
    padding: 10px;
    text-decoration: none;
    color: black;
    background: none;
    border: none;
    text-align: left;
    width: 100%;
    cursor: pointer;
  }
  
  .dropdown-menu a:hover,
  .dropdown-menu button:hover {
    background-color: #f0f0f0;
  }
  
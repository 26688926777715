/* bulkInvoice.css */

.bulk-invoice-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .invoice-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    flex: 1 1 45%;
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  .form-actions button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .form-actions button:hover {
    background-color: #0056b3;
  }
  /* General button styling */
button {
  background-color: #007bff; /* Default background color */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Hover effect for buttons */
button:hover {
  background-color: #0056b3; /* Darker background color on hover */
}


/* Disabled button styling */
button:disabled {
  background-color: #f0a6a6; /* Light coral when disabled */
  cursor: not-allowed;
  opacity: 0.6; /* Reduce opacity when disabled */
}

/* Disabled button hover effect */
button:disabled:hover {
  background-color: #f0a6a6; /* Same color on hover */
}



/* Add margins for spacing */
button + button {
  margin-left: 10px;
}

.sync-button-container {
  margin-top: 20px;
}
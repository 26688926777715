.customer-aging-summary {
    padding: 20px;
  }
  
  .export-section {
    margin-bottom: 10px;
  }
  
  .export-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .export-button:hover {
    background-color: #45a049;
  }
  
  .totals-row {
    margin-top: 10px;
    font-weight: bold;
  }
  
/* SalesTransactions.css */

.sales-transactions {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.sales-transactions-header {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filter-group {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
}

.filter-group.small {
  flex: 1 1 200px;
}

.filter-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.filter-group .input-field,
.filter-group select,
.filter-group .datepicker-container {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-group .datepicker-container .react-datepicker__input-container input {
  width: calc(100% - 30px);
}

.filter-group button {
  margin-top: auto;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.filter-group button:hover {
  background-color: #0056b3;
}

.totals-table {
  width: 100%;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  border-collapse: collapse;
}

.totals-table th, .totals-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.totals-table th {
  background-color: #e2e2e2;
}

.sales-transactions-detail .rdt_TableHead {
  background-color: #f8f8f8;
}

.datepicker-container .react-datepicker-wrapper {
  width: 100%;
}

.datepicker-container .react-datepicker__input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.transactions-and-form {
  display: flex;
  gap: 20px;
}

.transactions-list {
  flex: 1;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.transaction-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.transaction-item:hover {
  background-color: #e2e2e2;
}

.sales-form {
  flex: 2;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

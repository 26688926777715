.doctor-sales-details {
    padding: 20px;
  }
  
  .report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .report-logo {
    max-width: 150px;
    height: auto;
  }
  
  .close-doctor-sales-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
  }
  

  .header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .doctor-info-table,
  .earnings-summary-table {
    width: 35%;
    border-collapse: collapse;
  }
  
  .doctor-info-table td,
  .earnings-summary-table th,
  .earnings-summary-table td {
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .doctor-info-table {
    margin-right: 30px;
  }
  
  .data-table-container {
    margin-top: 20px;
  }
  
  .earnings-summary-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .print-button {
    margin-top: 20px;
  }
  
  /* Table layout for screen rendering */
  .data-table-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table-container th,
  .data-table-container td {
    padding: 0px;
    border: 1px solid #ddd;
    text-align: left;
    white-space: normal;  /* Ensure text wraps in headers */
    word-wrap: break-word;
  }

  
  /* Styling for print media */
 @media print {

      /* Make sure everything is set to landscape for printing */

      @page wide {
        size: A4 landscape;
        margin: 10mm;
      }
      

    * {
      visibility: hidden;
    }
  
    .doctor-sales-details,
    .doctor-sales-details * {
      visibility: visible;
    }
    .close-doctor-sales-button {
        visibility:hidden;
  }
    .data-table-container table {
      width: 100%;
      table-layout: fixed;  /* Ensure table has fixed layout for column control */
    }
  
    .data-table-container th, .data-table-container td {
      text-align: left;
      border: 1px solid #ddd;
      word-wrap: break-word;  /* Ensure words wrap properly within the cell */
      white-space: normal;    /* Ensure proper wrapping */
    }
  
   
  

  
    /* Ensure everything prints without page breaks */
    .doctor-info-table, 
    .earnings-summary-table, 
    .data-table-container {
      page-break-inside: avoid;
    }
  
    .print-button {
      visibility: hidden;  /* Hide print button */
    }
    
    /* Allow the entire table to be printed */
    .data-table-container {
      display: block;
      width: 100%;
      overflow: visible;
    }
    
  }
  
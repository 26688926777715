/* CSS for the table */
table {
    border:none;
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-weight:lighter;
    font-size: 12px;
  }
  
  /* Remove padding and spacing for all table cells */
 td,tr {
    padding:0;
    margin: 0;
    border:none;

  }
 
  th {
    border-top: 1px solid black;
    background-color:darkgray;
    text-align: left;
  }

  /* Optional: Add borders to table cells */

  .summaryTable {
    border-bottom: 1px solid black;
  }

/* Details Table */
.dateCell, .dateTotalCell, .itemsCell,.patientCell {
    padding-top: 5px;
    padding-left: 5px;
    padding-bottom: 5px;
    border-left: 1px solid black;
}
 .dateCell {
  width:20%;
 }

 .dateTotalCell {
    width:15%;
 }
 .itemsCell {
    width:50%;
 }
/* Header Detail Columns */
 .dateCellHeader {
    width:12%; /* 20% * 0.6 */
   }
  
   .dateTotalCellHeader {
      width:9%;  /* 15% * 0.6 */
      border-left: 1px solid black;
      border-right: 1px solid black;

   }
   .itemsCellHeader {
      width:30%; /* 50% * 0.6 */
      border-left: 1px solid black;
   }
   .patientCellHeader {
    width: 40%;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
 }

   .invoiceCell {
    width: 60%;

 }
 /*Main Table */
.patientCell {
    width:40%;
    text-align: left;
    vertical-align: top;
    border-bottom: 2px solid black;
    border-left: 1px solid black;
    border-top: 1px solid black;
}



.visitRecord {
    border:1px solid black;
}

 /* Wrapper Div */
.list-invoices {
    
    padding: 0; /* Remove padding for cell content */
    margin: 10px;
 
}

/* Header Styles */
#summaryHeader {
    text-align: center;
    margin-bottom: 40px; /* Add margin at the bottom for spacing */
    
}

.hospital-name{
    font-weight: bold;
    margin: 0; /* Remove default margin */
    font-size: 20px; /* Adjust font size */
}

.address {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
}
.address h3, .dateRange  {
    font-weight: normal;
    margin: 0; /* Remove default margin */
    font-size: 14px; /* Adjust font size */
    /* Set line height to 1 for tighter spacing */
}

.customer-name {
    margin-top: 10px; /* Adjust the spacing between hospital and customer name */
    margin-bottom: 0; /* Remove bottom margin for tighter spacing */
    font-size: 20px; /* Adjust font size */
     /* Set line height to 1 for tighter spacing */
}

#totalSummary {
    
    font-size: 16px;
    margin-bottom: 20px;
}
.summaryTotalsTable {
    width:40%;
}
.summaryTotalsTable tr {
    border-bottom: 1px dotted black;
}

.exportButton {
    width: 130px;
    margin-left: 10px;
}

  button:disabled {
    background-color: #CCCCCC; /* Light gray */
    color: #666666; /* Dark gray */
    cursor: not-allowed;
  }

  .separator {
    border-top: 1px solid #ccc; /* Horizontal line with a light gray color */
    margin-top: 20px; /* Adjust the spacing between the line and the content below */
  }

  .editIcon {
    display: inline-block; /* Ensure the icon is displayed as an inline block */
}
 .cancelIcon {
    display: inline-block;
 }

 .editButtons {
   display: flex;
 }

 .ci-button-container {
  display: flex;
  gap: 10px;
}
.ci-button-container .bulk-button {
  background-color: #007bff; /* Default background color */
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}


.ci-button-container .print-button, 
.ci-button-container .excel-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px; /* Adjust size as needed */
  color: #007bff;
  margin-top: 0px;
}

.printButton:hover {
  background-color: #0056b3; /* Darker blue background color on hover */

}

.pdf-mode .exclude-from-pdf {
  display: none;
}

.pdf-mode .patientRecord {
  break-inside:avoid;
}



@media print {

  .list-invoices {
  @page {
    size: A4 portrait;
    margin: 10mm;
  }
}
.patientRecord {
  break-inside: avoid;
}

  body {
    margin: 0;
    padding: 0;
    font-size: 12pt;
  }

  .list-invoices {
      position: absolute;
      top: 30px;
      left: 0;
      width: 210mm; /* A4 width */
      height: 297mm auto; /* A4 height */
      margin: 0 auto;
      padding: 0mm;
    }
  

  body * {
      visibility: hidden;
  }
  .list-invoices,
  .list-invoices * {
    visibility: visible;
  }
  .editIcon {
      display: none; /* Hide the pencil icon when printing */
  }
  .cancelIcon {
      display: none; /* Hide the pencil icon when printing */
  }
}
.landing-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .links-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .link-card {
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    color: #014385;
    width: 150px;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin:10px;
  }
  
  .link-card:hover {
    background-color: #007bff;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    margin-bottom: 10px;
  }
 
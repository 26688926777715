.employee-payments-search-container {
    padding: 20px;
  }
  
  .filter-form {
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .form-group {
    flex: 1 1 45%;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group select,
  .form-group input {
    display: flex;
    width: 50%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-actions {
    margin-top: 20px;
  }
  
  .data-table-container {
    margin-top: 20px;
  }
  
  .export-button {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .export-button:hover {
    background-color: #45a049;
  }
  
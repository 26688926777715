.login-container {
  width: 100%;
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-container h2 {
  margin-bottom: 20px;
  color: #333;
}

.login-form, .reset-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-group label {
  margin-bottom: 5px;
  color: #555;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.forgot-password {
  margin-top: 10px;
  font-size: 0.9em;
}

.reset-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.reset-link:hover {
  color: #0056b3;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.btn-login, .btn-forgot-password, .btn-reset {
  padding: 10px 15px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-login {
  background-color: #007bff;
  color: white;
}

.btn-login:hover {
  background-color: #0056b3;
}

.btn-forgot-password {
  background-color: transparent;
  color: #007bff;
  text-decoration: underline;
  border: none;
}

.btn-reset {
  background-color: #28a745;
  color: white;
}

.btn-reset:hover {
  background-color: #218838;
}

.sales-view-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .invoice-header {
    margin-bottom: 20px;
  }
  
  .invoice-title h2,
  .invoice-title h3 {
    margin: 0;
  }
  
  .invoice-title h3 {
    font-weight: normal;
  }
  
  .invoice-details {
    margin-top: 10px;
  }
  
  .invoice-details div {
    margin-bottom: 8px;
  }
  
  .invoice-items table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .invoice-items th,
  .invoice-items td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .invoice-items th {
    background-color: #f8f8f8;
  }
  
  .invoice-summary {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .invoice-summary div {
    margin-bottom: 8px;
  }
  
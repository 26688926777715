/* SalesQueue.css */
.sales-queue-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.sales-queue-list {
  list-style-type: none;
  padding: 0;
  margin-right: 20px;
  flex: 1;
}

.sales-queue-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.sales-queue-item div {
  display: flex;
  flex-direction: column;
}

.sales-queue-item span {
  margin: 5px 0;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.icon-button:hover {
  color: #007bff;
}

.delete-button:hover {
  color: red;
}

.queue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.queue-controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.toggle-button {
  padding: 10px 20px;
  margin-right: 10px; /* Add space between button and indicator */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.sync-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}

.sync-indicator.green {
  background-color: green;
}

.sync-indicator.red {
  background-color: red;
}

.queue-and-form {
  display: flex;
  margin-top: 10px;
}

.sales-form-container {
  flex: 2;
  margin-left: 20px; /* Add some spacing between the queue and the form */
}

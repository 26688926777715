/* InvoiceItem.css */

  
.invoice-table {
    margin: 0px;
    border: none;
    width: 100%;
    border-collapse: collapse;
    height: 40px;
  }

  .invoice-table td {
    border:none;
    text-align: left;
}
  .typeCell {
    width:75%;
    border-bottom: none;
    
  }
  .itemsSections {
    border:none;
  }

.role-permissions-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
}

/* Sidebar Styling */
.sidebar {
  width: 250px;
  border-right: 1px solid #ddd;
  padding: 20px;
  background-color: #fff;
}

.sidebar h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.role-button {
  display: block;
  width: 100%;
  text-align: left;
  background-color: #f4f4f4;
  color: #333;
  border: none;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.role-button.active {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.role-button:hover:not(.active) {
  background-color: #e2e6ea;
}

/* Add Role Section */
.add-role-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.add-role-input {
  padding: 8px 10px;
  font-size: 1em;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-role-button {
  display: inline-block;
  background-color: #28a745;
  color: #fff;
  padding: 10px 15px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.add-role-button:hover {
  background-color: #218838;
}

/* Permissions Section */
.permissions-section {
  flex-grow: 1;
  padding: 20px;
  background-color: #fff;
  margin-left: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.permissions-section h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333;
}

.accordion-item {
  margin-bottom: 20px;
}

.accordion-header {
  background-color: #007bff;
  color: #fff;
  padding: 0px 0px;
  border-radius: 4px;
  font-size: 1.0em;
  font-weight: bold;
}

.accordion-body {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  margin-top: 5px;
}

.permission-label {
  display: inline-block;
  margin-right: 15px;
  font-size: 0.9em;
  color: #333;
}

.permission-label input {
  margin-right: 5px;
  cursor: pointer;
}

/* Save Button */
.btn-save {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 1em;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-save.saving {
  background-color: #5a6268;
  cursor: not-allowed;
}

.btn-save:hover:not(.saving) {
  background-color: #0056b3;
}

/* Container for Sync Data section */
.data-sync-container {
  display: block;
  align-items: center;
}

/* Button styling for Sync Data */
.backup-button {
  background-color: lightcoral;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.backup-button.disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.backup-button:hover:not(.disabled) {
  background-color: darkred;
}

/* Sync status message styling */
.sync-status-message {
  margin-right: 10px;
}

/* Status text styling */
.sync-status-text-clean {
  color: green;
}

.sync-status-text-dirty {
  color: red;
}
.doctor-sales-summary {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  .filter-group {
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
  
  .filter-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .filter-group button {
    margin-top: 25px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .filter-group button:disabled {
    background-color: #ccc;
  }
  
  .data-table-container {
    margin-top: 20px;
  }
  
  .btn.btn-primary {
    margin-top: 20px;
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
  }
  
  .btn.btn-primary:hover {
    background-color: #0056b3;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .icon-button .fa {
    font-size: 1.2em;
  }
  
  .icon-button .fa:hover {
    color: #007bff;
  }
  